.navbar-inverse .navbar-brand {
    color: #fff;
    font-weight: 100;
    font-family: Oxygen, Helvetica, sans-serif;
    letter-spacing: 0.01em;
}

.sidebar {
    background-color: #FFF;
    color: #444;
}
.navigation li a,
.navigation > li ul li a {
    color: #444;
    font-weight: 300;
}
.navigation li a:hover,
.navigation li a:focus {
    color: #444;
}

.navigation > li.active > a,
.navigation > li.active > a:hover,
.navigation > li.active > a:focus {
    background-color: #9cb3c0;
}


.navigation > li ul {
    background-color: rgba(0, 0, 0, 0.07);
}

.navigation .navigation-header,
.navigation .navigation-header a {
    color: #000;
}

.navbar-inverse {
    background-color: #436890;
    border-color: #37474F;
}

@media screen and (min-width: 769px) {

    .sidebar {
        border-right: 1px solid #ddd;
    }

    .nav-tabs.nav-tabs-bottom > li.active > a:after {
        background-color: #436890;
    }
}